// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-avatar-mdx": () => import("./../src/pages/avatar.mdx" /* webpackChunkName: "component---src-pages-avatar-mdx" */),
  "component---src-pages-blog-5-meo-dmt-md": () => import("./../src/pages/blog/5-Meo-Dmt.md" /* webpackChunkName: "component---src-pages-blog-5-meo-dmt-md" */),
  "component---src-pages-blog-advaita-md": () => import("./../src/pages/blog/Advaita.md" /* webpackChunkName: "component---src-pages-blog-advaita-md" */),
  "component---src-pages-blog-advaita-vedanta-md": () => import("./../src/pages/blog/Advaita Vedanta.md" /* webpackChunkName: "component---src-pages-blog-advaita-vedanta-md" */),
  "component---src-pages-blog-anandamide-md": () => import("./../src/pages/blog/Anandamide.md" /* webpackChunkName: "component---src-pages-blog-anandamide-md" */),
  "component---src-pages-blog-baba-md": () => import("./../src/pages/blog/baba.md" /* webpackChunkName: "component---src-pages-blog-baba-md" */),
  "component---src-pages-blog-bitcoin-and-billgates-md": () => import("./../src/pages/blog/bitcoin and billgates.md" /* webpackChunkName: "component---src-pages-blog-bitcoin-and-billgates-md" */),
  "component---src-pages-blog-bitcoin-md": () => import("./../src/pages/blog/Bitcoin.md" /* webpackChunkName: "component---src-pages-blog-bitcoin-md" */),
  "component---src-pages-blog-bitcoin-mining-md": () => import("./../src/pages/blog/bitcoin mining.md" /* webpackChunkName: "component---src-pages-blog-bitcoin-mining-md" */),
  "component---src-pages-blog-brahman-md": () => import("./../src/pages/blog/Brahman.md" /* webpackChunkName: "component---src-pages-blog-brahman-md" */),
  "component---src-pages-blog-divinity-of-shakti-md": () => import("./../src/pages/blog/Divinity of Shakti.md" /* webpackChunkName: "component---src-pages-blog-divinity-of-shakti-md" */),
  "component---src-pages-blog-dmt-md": () => import("./../src/pages/blog/DMT.md" /* webpackChunkName: "component---src-pages-blog-dmt-md" */),
  "component---src-pages-blog-duality-and-non-duality-md": () => import("./../src/pages/blog/Duality and Non-duality.md" /* webpackChunkName: "component---src-pages-blog-duality-and-non-duality-md" */),
  "component---src-pages-blog-energy-md": () => import("./../src/pages/blog/Energy.md" /* webpackChunkName: "component---src-pages-blog-energy-md" */),
  "component---src-pages-blog-four-pillars-md": () => import("./../src/pages/blog/Four Pillars.md" /* webpackChunkName: "component---src-pages-blog-four-pillars-md" */),
  "component---src-pages-blog-ganges-md": () => import("./../src/pages/blog/Ganges.md" /* webpackChunkName: "component---src-pages-blog-ganges-md" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-kali-md": () => import("./../src/pages/blog/kali.md" /* webpackChunkName: "component---src-pages-blog-kali-md" */),
  "component---src-pages-blog-live-in-moment-md": () => import("./../src/pages/blog/Live in Moment.md" /* webpackChunkName: "component---src-pages-blog-live-in-moment-md" */),
  "component---src-pages-blog-matrix-md": () => import("./../src/pages/blog/Matrix.md" /* webpackChunkName: "component---src-pages-blog-matrix-md" */),
  "component---src-pages-blog-meaning-of-life-md": () => import("./../src/pages/blog/Meaning of Life.md" /* webpackChunkName: "component---src-pages-blog-meaning-of-life-md" */),
  "component---src-pages-blog-nirvana-md": () => import("./../src/pages/blog/Nirvana.md" /* webpackChunkName: "component---src-pages-blog-nirvana-md" */),
  "component---src-pages-blog-notes-md": () => import("./../src/pages/blog/notes.md" /* webpackChunkName: "component---src-pages-blog-notes-md" */),
  "component---src-pages-blog-numbers-md": () => import("./../src/pages/blog/Numbers.md" /* webpackChunkName: "component---src-pages-blog-numbers-md" */),
  "component---src-pages-blog-patterns-and-reality-md": () => import("./../src/pages/blog/Patterns and Reality.md" /* webpackChunkName: "component---src-pages-blog-patterns-and-reality-md" */),
  "component---src-pages-blog-psychic-md": () => import("./../src/pages/blog/psychic.md" /* webpackChunkName: "component---src-pages-blog-psychic-md" */),
  "component---src-pages-blog-ramana-md": () => import("./../src/pages/blog/Ramana.md" /* webpackChunkName: "component---src-pages-blog-ramana-md" */),
  "component---src-pages-blog-reality-md": () => import("./../src/pages/blog/Reality.md" /* webpackChunkName: "component---src-pages-blog-reality-md" */),
  "component---src-pages-blog-sat-chit-ananda-md": () => import("./../src/pages/blog/Sat-Chit-Ananda.md" /* webpackChunkName: "component---src-pages-blog-sat-chit-ananda-md" */),
  "component---src-pages-blog-satori-md": () => import("./../src/pages/blog/Satori.md" /* webpackChunkName: "component---src-pages-blog-satori-md" */),
  "component---src-pages-blog-shiva-and-shakti-md": () => import("./../src/pages/blog/Shiva and Shakti.md" /* webpackChunkName: "component---src-pages-blog-shiva-and-shakti-md" */),
  "component---src-pages-blog-suffering-md": () => import("./../src/pages/blog/Suffering.md" /* webpackChunkName: "component---src-pages-blog-suffering-md" */),
  "component---src-pages-blog-sunyata-md": () => import("./../src/pages/blog/Sunyata.md" /* webpackChunkName: "component---src-pages-blog-sunyata-md" */),
  "component---src-pages-blog-tantra-yogas-md": () => import("./../src/pages/blog/Tantra Yogas.md" /* webpackChunkName: "component---src-pages-blog-tantra-yogas-md" */),
  "component---src-pages-blog-transhumanism-md": () => import("./../src/pages/blog/Transhumanism.md" /* webpackChunkName: "component---src-pages-blog-transhumanism-md" */),
  "component---src-pages-blog-transmigration-of-soul-md": () => import("./../src/pages/blog/Transmigration of Soul.md" /* webpackChunkName: "component---src-pages-blog-transmigration-of-soul-md" */),
  "component---src-pages-context-mdx": () => import("./../src/pages/context.mdx" /* webpackChunkName: "component---src-pages-context-mdx" */),
  "component---src-pages-getstarted-mdx": () => import("./../src/pages/getstarted.mdx" /* webpackChunkName: "component---src-pages-getstarted-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-login-mdx": () => import("./../src/pages/login.mdx" /* webpackChunkName: "component---src-pages-login-mdx" */),
  "component---src-pages-notes-index-mdx": () => import("./../src/pages/notes/index.mdx" /* webpackChunkName: "component---src-pages-notes-index-mdx" */),
  "component---src-pages-whitepaper-mdx": () => import("./../src/pages/whitepaper.mdx" /* webpackChunkName: "component---src-pages-whitepaper-mdx" */)
}

